import React, { useContext, useState } from 'react';

import * as S from './styles';

import { ThemeProvider } from 'styled-components';
import { light, common } from '../../../styles/GlobalStyle/themes';
import {
  company,
  phoneService,
  sieadImagesFolderUrl,
} from '../../../variables';
import { FormLogin, RecoverPassword } from './components';

import GlobalStyle from '../../../styles/GlobalStyle/global';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../components/newStudent/ProviderNewStudent';
import LoadingNewStudent from '../../../components/common/Loading';
import NewPassword from './components/NewPassword';
import CreateFirstPassword from './components/CreatePassword';
import { useQuery } from '../../../util/common/queryUtil';
import ChatFloating from '../../../components/newStudent/Layout/components/ChatFloating';

const logo = `${sieadImagesFolderUrl}white_logo.png`;

export default function LoginNewStudent() {
  const { loading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [fieldLogin, setFieldLogin] = useState<string>('');
  const [createFirstPassword, setCreateFirstPassword] = useState(false);
  const [recovery, setRecovery] = useState(false);

  const params = useQuery();

  const renderContent = () => {
    if (params.get('token'))
      return <NewPassword token={params.get('token') || ''} />;
    else if (recovery) return <RecoverPassword setRecovery={setRecovery} />;
    else if (createFirstPassword)
      return (
        <CreateFirstPassword
          setCreateFirstPassword={setCreateFirstPassword}
          fieldLogin={fieldLogin}
          setFieldLogin={setFieldLogin}
        />
      );
    else
      return (
        <FormLogin
          setRecovery={setRecovery}
          setCreateFirstPassword={setCreateFirstPassword}
          setFieldLogin={setFieldLogin}
          fieldLogin={fieldLogin}
        />
      );
  };

  return (
    <ThemeProvider theme={{ ...light, ...common }}>
      {loading && <LoadingNewStudent />}
      <GlobalStyle />
      <S.Container>
        <S.ContainerImg>
          <S.BackgroundImg
            className="background_img"
            src={
              company?.toLowerCase() === 'famart'
                ? '/assets/img/bg_image.png'
                : '/assets/img/bg_image_ipb.png'
            }
            alt="login_image"
          />
          <S.ContainerInfo className="container_info">
            <S.Img src={logo} alt="logo" width="200px" />
            <S.Info>
              <S.P>
                Em caso de dúvidas, entre em contato com a Central do Aluno da
                Faculdade Famart pelo contato <S.B>{phoneService}.</S.B>
              </S.P>
            </S.Info>
          </S.ContainerInfo>
        </S.ContainerImg>
        <S.Content>{renderContent()}</S.Content>
        <S.BackgroundImgText
          className="background_img_text"
          src="/assets/img/bg_text.png"
          alt="background_img_text"
        />
      </S.Container>
      
      <ChatFloating
        student={false}
      />
    </ThemeProvider>
  );
}
