import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import FormField from '../../../../../FormField';

import * as S from './styles';
import {
  invoiceIugu,
  creditCardIugu,
  pixIugu,
} from '../../../../../../../variables';
import { numberToReal } from '../../../../../../../util/agent/money';
import { IIsItemsEmpty } from '../..';
import { INewNegotiationSale } from '../../../../../../../pages/agent/MyBusiness/components/ModalNewStudentData';
import {IDiscountSale, IPayment} from '../../../../../../../services/agent/student/interface';
import { isComboThreePosFixedNineteenParcels } from '../../../../../../../util/agent/rules';
import {ICoupon} from "../SaleInformation";

interface IParcels {
  quantity: number;
  discount: number;
}
interface IPaymentType {
  id?: number;
  parcels?: IParcels[];
  description?: string;
}
interface Props {
  payment: IPayment;
  setPayment: Dispatch<SetStateAction<IPayment>>;
  isItemsEmpty: IIsItemsEmpty;
  send: boolean;
  paymentTypeValue: IPaymentType[];
  valueTotal: number;
  idCardDiscount: number;
  negotiation: INewNegotiationSale;
  setNegotiation: Dispatch<SetStateAction<INewNegotiationSale>>;
  setCoupon: Dispatch<SetStateAction<ICoupon>>;
  setDiscount: Dispatch<SetStateAction<IDiscountSale>>;
}

export default function SaleRegistration({
  payment,
  setPayment,
  isItemsEmpty,
  send,
  paymentTypeValue,
  valueTotal,
  negotiation,
  setNegotiation,
  idCardDiscount,
  setCoupon,
  setDiscount,
}: Props) {
  /** Criação das opçoes da forma de pagamento do Plano estudantil */
  const optionPlanType = useMemo(() => {
    const options = [
      { title: '[ SELECIONE ]', value: undefined },
      ...(paymentTypeValue.length > 0
        ? paymentTypeValue
            .filter((item) => item.description)
            .map((item) => ({
              title: item.description!.split(' ')[0],
              value: item.id,
            }))
        : []),
    ];

    return options;
  }, [paymentTypeValue]);

  const [parcelsValue, setParcelsValue] = useState(0);

  /** Configura quais as formas de pagamento disponíveis para o Plano Estudantil */
  const parcelsFormPgto = useMemo(() => {
    const index = optionPlanType.find(
      (opt) =>
        opt?.value !== undefined &&
        +opt.value === +payment!.studentPlan.paymentType!,
    );
    let parcel = index
      ? paymentTypeValue.find((pay) => pay.id === index.value)?.parcels
      : [];

    if (!isComboThreePosFixedNineteenParcels(idCardDiscount)) {
      parcel = parcel?.filter((p) => p.quantity < 19);
    }

    if (
      negotiation.registration.unified &&
      payment!.studentPlan.paymentType === invoiceIugu
    ) {
      return [
        { title: '[ SELECIONE ]', value: undefined },
        { title: `1x`, value: 1 },
      ];
    } else {
      return [
        { title: '[ SELECIONE ]', value: undefined },

        ...parcel!.map((i) => {
          return { title: `${i.quantity}x`, value: i.quantity };
        }),
      ];
    }
  }, [
    optionPlanType,
    paymentTypeValue,
    payment!.studentPlan.paymentType,
    idCardDiscount,
  ]);

  /** Configura qual o valor da parcela a ser paga */
  useEffect(() => {
    setParcelsValue(0);

    if (payment.studentPlan.numberParcels! >= 1) {
      if (negotiation.registration.unified) {
        setParcelsValue(valueTotal / +payment.studentPlan.numberParcels!);
      } else {
        const valueRegistration = negotiation.registration.value
          ? negotiation.registration.value
          : 0;

        setParcelsValue(
          (valueTotal - valueRegistration) /
            +payment.studentPlan.numberParcels!,
        );
      }
    }
  }, [payment!.studentPlan.numberParcels, valueTotal]);

  return (
    <S.Container>
      <S.ContentRegistration>
        <S.Title>Matrícula</S.Title>
        <S.Content>
          <S.Checkbox>
            <b>Unificada</b>
            <input
              style={{ margin: 0 }}
              checked={payment.registration.unified}
              disabled={
                isItemsEmpty.registration ||
                payment.registration.registrationFree
              }
              onChange={({ target }) => {
                setPayment((old) => ({
                  ...old,
                  registration: {
                    ...old.registration,
                    unified: target.checked,
                    dueDate: payment.studentPlan.dueDate,
                  },
                  studentPlan: {
                    ...old.studentPlan,
                    paymentType: 0,
                    numberParcels: 0,
                  },
                }));
              }}
              type="checkbox"
            />
          </S.Checkbox>
          <S.Checkbox>
            <b>Isentar</b>
            <input
              style={{ margin: 0 }}
              checked={payment.registration.registrationFree}
              disabled={isItemsEmpty.registration}
              onChange={({ target }) => {
                setPayment((old) => ({
                  ...old,
                  registration: {
                    registrationFree: target.checked,
                    dueDate: '',
                    value: undefined,
                    paymentType: undefined,
                    unified: false,
                  },
                  studentPlan: {
                    ...old.studentPlan,
                    paymentType: 0,
                    numberParcels: 0,
                  },
                }));
              }}
              type="checkbox"
            />
          </S.Checkbox>
          <FormField
            item={{
              disabled:
                isItemsEmpty.registration ||
                payment.registration.registrationFree,
              isInvalid:
                send &&
                !payment.registration.value &&
                !payment.registration.registrationFree,
              handleChange: ({ target }) => {
                +target.value >= 0
                  ? setPayment((old) => ({
                      ...old,
                      registration: {
                        ...old.registration,
                        value: target.value,
                      },
                    }))
                  : 0;
              },
              placeholder: 'R$ 0,00',
              title: 'Valor',
              type: 'number',
              value: payment.registration.value,
            }}
          />
          <FormField
            item={{
              disabled:
                isItemsEmpty.registration ||
                payment.registration.unified ||
                payment.registration.registrationFree,
              isInvalid:
                send &&
                !payment.registration.paymentType &&
                !payment.registration.unified &&
                !payment.registration.registrationFree,
              handleChange: ({ target }) => {
                setPayment((old) => ({
                  ...old,
                  registration: {
                    ...old.registration,
                    paymentType: target.value,
                  },
                }));
              },
              title: 'Forma de Pagamento',
              type: 'select',
              value: payment.registration.unified
                ? payment.studentPlan.paymentType
                : payment.registration.paymentType,
              options: [
                { title: '[ SELECIONE ]', value: undefined },
                { title: 'BOLETO', value: invoiceIugu },
                { title: 'CARTÃO', value: creditCardIugu },
                { title: 'PIX', value: pixIugu },
              ],
            }}
          />
          <FormField
            item={{
              disabled:
                isItemsEmpty.registration ||
                payment.registration.unified ||
                payment.registration.registrationFree,
              isInvalid:
                send &&
                !payment.registration.dueDate &&
                !payment.registration.unified &&
                !payment.registration.registrationFree,
              handleChange: ({ target }) => {
                setPayment((old) => ({
                  ...old,
                  registration: { ...old.registration, dueDate: target.value },
                }));
              },
              type: 'date',
              title: 'Data de Vencimento',
              value: payment.registration.dueDate,
            }}
          />
        </S.Content>
      </S.ContentRegistration>
      <S.Content>
        <S.Title>Plano Estudantil</S.Title>
        <S.Checkbox>
          <b>Isentar</b>
          <input
            style={{ margin: 0 }}
            checked={payment.studentPlan.studentPlanFree}
            disabled={isItemsEmpty.registration}
            onChange={({ target }) => {
              setPayment((old) => ({
                ...old,
                studentPlan: {
                  studentPlanFree: target.checked,
                  dueDate: undefined,
                  total: 0,
                  paymentType: 0,
                  numberParcels: 0,
                },
              }));
            }}
            type="checkbox"
          />
        </S.Checkbox>
        <S.Content>
          <FormField
            item={{
              disabled:
                isItemsEmpty.registration ||
                payment.studentPlan.studentPlanFree,
              handleChange: ({ target }) => {
                setPayment((old) => ({
                  ...old,
                  studentPlan: {
                    ...old.studentPlan,
                    paymentType: target.value,
                    numberParcels: 0,
                  },
                }));
                setNegotiation((old) => ({
                  ...old,
                  discounts: {
                    ...old.discounts,
                    paymentType: {
                      value: '0',
                      maxValue: '0',
                    },
                  coupon: {
                      valueDiscount: 0,
                      value: 0,
                      text: '',
                  },
                  },
                }));
                  setCoupon(() => ({
                      valueDiscount: 0,
                      value: 0,
                      text: undefined,
                  }));

                  setDiscount((old) => ({
                      ...old,
                      coupon: {
                          valueDiscount: 0,
                          value: 0,
                          text: '',
                      },
                  }));
              },
              isInvalid:
                send &&
                !payment.studentPlan.paymentType &&
                !payment.studentPlan.studentPlanFree,
              title: 'Forma de Pagamento',
              type: 'select',
              value: payment.studentPlan.paymentType,
              options: optionPlanType,
            }}
          />
          <FormField
            item={{
              disabled:
                isItemsEmpty.registration ||
                payment.studentPlan.studentPlanFree,
              handleChange: ({ target }) => {
                setPayment((old) => ({
                  ...old,
                  studentPlan: {
                    ...old.studentPlan,
                    numberParcels: target.value,
                  },
                }));
                setNegotiation((old) => ({
                  ...old,
                  discounts: {
                    ...old.discounts,
                    paymentType: {
                      value: '0',
                      maxValue: '0',
                    },
                  },
                }));
              },
              type: 'select',
              isInvalid:
                send &&
                !payment.studentPlan.numberParcels &&
                !payment.studentPlan.studentPlanFree,
              title: 'Número de parcelas',
              value: payment.studentPlan.numberParcels,
              options: parcelsFormPgto,
            }}
          />
          <FormField
            item={{
              disabled:
                isItemsEmpty.registration ||
                payment.studentPlan.studentPlanFree,
              handleChange: ({ target }) => {
                setPayment((old) => ({
                  ...old,
                  studentPlan: { ...old.studentPlan, dueDate: target.value },
                }));
              },
              type: 'date',
              title: 'Primeiro Vencimento',
              isInvalid:
                send &&
                !payment.studentPlan.dueDate &&
                !payment.studentPlan.studentPlanFree,
              value: payment.studentPlan.dueDate || '',
            }}
          />
          <S.ValueParcels>
            <b style={{ fontSize: '14px' }}>Valor Parcelas</b>
            <b style={{ fontSize: '14px', color: 'green' }}>
              {numberToReal(parcelsValue)}
            </b>
          </S.ValueParcels>
        </S.Content>
      </S.Content>
    </S.Container>
  );
}
